import { FC, Suspense, lazy } from 'react';
import { GrayscaleProgress } from 'components';
import { useTypedParams } from 'utils';

const ConfiguredScreenVersionConfigPage = lazy(
  () => import('features/configured-screen-versions/components/ConfiguredScreenVersionConfigPage')
);

const ConfiguredScreenVersionRoute: FC = () => {
  const { versionId } = useTypedParams({
    versionId: Number,
  });

  return (
    <Suspense fallback={<GrayscaleProgress />}>
      <ConfiguredScreenVersionConfigPage versionId={versionId} />
    </Suspense>
  );
};

export default ConfiguredScreenVersionRoute;
