import { ConfiguredScreen } from '../types';
import { ConfiguredScreenDTO } from './types';

const getConfiguredScreenFromDTO = (dto: ConfiguredScreenDTO): ConfiguredScreen => {
  const createdAt = new Date(dto.created_at);
  const updatedAt = new Date(dto.updated_at);

  const configuredScreen = {
    ...dto,
    created_at: createdAt,
    updated_at: updatedAt,
  };

  return configuredScreen;
};

export default getConfiguredScreenFromDTO;
