import { FC, Suspense, lazy } from 'react';
import { ReviewPageSkeleton } from 'features/variants';

const ConfiguredScreenPage = lazy(() => import('features/configured-screens/components/ConfiguredScreenPage'));

const ConfiguredScreenRoute: FC = () => (
  <Suspense fallback={<ReviewPageSkeleton />}>
    <ConfiguredScreenPage />
  </Suspense>
);

export default ConfiguredScreenRoute;
