import { useProject } from 'features/project';
import { useTypedParams } from 'utils';
import { UseGetFlowQuery, useGetFlowQuery } from '../api';

export type UseGetFlow = {
  config?: UseGetFlowQuery['config'];
  flowId?: number;
};

export const useGetFlow = ({ config, flowId: flowIdProp }: UseGetFlow = {}) => {
  const project = useProject();
  const projectId = project.id;

  const { flowId } = useTypedParams({
    flowId: Number,
  });

  return useGetFlowQuery({
    config,
    projectId,
    flowId: flowIdProp ?? flowId,
  });
};
