import { FC, ReactNode, PropsWithChildren, SyntheticEvent } from 'react';
import { Autocomplete, CircularProgress, SxProps, TextField, TextFieldProps, Theme } from '@mui/material';
import { Template, TemplateType } from 'features/templates';
import { useStateSearch } from 'hooks';
import { useGetTemplatesAutocomplete } from '../hooks';

interface TemplatesAutocompleteProps extends PropsWithChildren {
  value: Template | null;
  type?: TemplateType | null;
  fallback?: ReactNode;
  onChange: (event: SyntheticEvent, val: Template | null) => void;
  InputProps?: TextFieldProps;
  sx?: SxProps<Theme>;
}

const TemplatesAutocomplete: FC<TemplatesAutocompleteProps> = ({
  value,
  onChange,
  type = null,
  InputProps,
  fallback,
  ...props
}) => {
  const { search, searchFieldValue, handleSearchFieldChange } = useStateSearch();

  const { data, isPending, isFetched } = useGetTemplatesAutocomplete({
    type: type!,
    params: { search, page: 1, per_page: 100 },
    config: { enabled: Boolean(type) },
  });

  const templates = data?.items! || [];

  const noTemplatesCreated = !search && isFetched && !templates.length;

  return !noTemplatesCreated || !fallback ? (
    <Autocomplete
      {...props}
      value={value}
      options={templates}
      getOptionLabel={(option: Template) => option.identifier}
      onChange={onChange}
      loading={isPending}
      filterOptions={(x) => x}
      renderInput={(params: any) => (
        <TextField
          {...params}
          {...InputProps}
          onChange={handleSearchFieldChange}
          value={searchFieldValue}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isPending ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      loadingText="Завантажуємо..."
    />
  ) : (
    fallback
  );
};

export default TemplatesAutocomplete;
