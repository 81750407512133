import { Paper, Box, TextField, Button } from '@mui/material';
import { FC, Suspense, lazy } from 'react';
import { useModal, usePagination, useSearch, useSorting } from 'hooks';
import TemplateListSkeleton from 'features/templates/components/TemplateListSkeleton';
import { ConfiguredScreen } from 'features/configured-screens';
import { Add as AddIcon } from '@mui/icons-material';
import { useGetFlow } from 'features/flows';
import CreateConfiguredScreenDialog from 'features/configured-screens/components/CreateConfiguredScreenDialog';

const ConfiguredScreensList = lazy(() => import('features/configured-screens/components/ConfiguredScreensList'));

const ConfiguredScreensListRoute: FC = () => {
  const { search, searchFieldValue, handleSearchFieldChange } = useSearch();
  const sorting = useSorting<ConfiguredScreen>('identifier');
  const pagination = usePagination([search, sorting.field, sorting.order]);

  const creationModal = useModal();

  const flowQuery = useGetFlow();
  const flow = flowQuery.data!;

  return (
    <>
      <CreateConfiguredScreenDialog isOpen={creationModal.isOpen} onClose={creationModal.close} flowId={flow.id} />
      <Paper>
        <Box sx={{ p: 2, display: 'flex', alignItems: 'center', gap: 2 }}>
          <TextField label="Search" placeholder="ID, etc" onChange={handleSearchFieldChange} value={searchFieldValue} />
          <Button
            variant="contained"
            size="large"
            onClick={creationModal.open}
            startIcon={<AddIcon />}
            sx={{ ml: 'auto' }}
          >
            Create
          </Button>
        </Box>
        <Suspense fallback={<TemplateListSkeleton />}>
          <ConfiguredScreensList pagination={pagination} sorting={sorting} search={search} />
        </Suspense>
      </Paper>
    </>
  );
};

export default ConfiguredScreensListRoute;
