import { APIListResponse, ListResponse, apiClient, getAPIListResponse } from 'api';
import { QueryConfig, SynchronousQueryConfig } from 'lib/react-query';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import queryKeys from './queryKeys';
import { Template, TemplateType } from '../types';
import { TemplateDTO, TemplatesQueryParams } from './types';
import getTemplateFromDTO from './getTemplateFromDTO';

export type GetTemplatesOptions = {
  projectId: number;
  type: TemplateType;
  params: TemplatesQueryParams;
};

export const getTemplates = async (
  { projectId, type, params }: GetTemplatesOptions,
  signal?: AbortSignal
): Promise<ListResponse<Template>> => {
  const res = await apiClient.get<APIListResponse<TemplateDTO>>(`/admin/projects/${projectId}/templates`, {
    params: { ...params, type },
    signal,
  });

  const convertedData = getAPIListResponse(res.data);

  return {
    items: convertedData.items.map((template) => getTemplateFromDTO(template)),
    total: convertedData.total,
  };
};

export type UseGetTemplatesQuery = {
  config?: QueryConfig<typeof getTemplates>;
  projectId: number;
  type: TemplateType;
  params: TemplatesQueryParams;
};

export const useGetTemplatesQuery = ({ config, projectId, type, params }: UseGetTemplatesQuery) =>
  useSuspenseQuery({
    gcTime: 1000,
    retry: false,
    ...config,
    queryFn: ({ signal }) => getTemplates({ projectId, type, params }, signal),
    queryKey: queryKeys(projectId).list(type, params),
  });

export type UseGetSynchronousTemplatesQuery = {
  config?: SynchronousQueryConfig<typeof getTemplates>;
  projectId: number;
  type: TemplateType;
  params: TemplatesQueryParams;
};

export const useGetSynchronousTemplatesQuery = ({ config, projectId, type, params }: UseGetSynchronousTemplatesQuery) =>
  useQuery({
    gcTime: 1000,
    retry: false,
    ...config,
    queryFn: ({ signal }) => getTemplates({ projectId, type, params }, signal),
    queryKey: queryKeys(projectId).list(type, params),
  });
