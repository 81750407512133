import { Box, Button, Dialog, Typography, Link } from '@mui/material';
import { FC, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Template, TemplateType } from 'features/templates';
import TemplatesAutocomplete from 'features/templates/components/TemplatesAutocomplete';
import { ModalPageLayout } from 'components';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useProject } from 'features/project';
import { useCreateConfiguredScreen } from '../hooks';

interface CreateConfiguredScreenDialogProps {
  type?: TemplateType;
  isOpen: boolean;
  flowId: number;
  onClose: (action?: 'declined') => void;
}

const CreateConfiguredScreenDialog: FC<CreateConfiguredScreenDialogProps> = ({
  type = TemplateType.Screen,
  flowId,
  isOpen,
  onClose,
}) => {
  const navigate = useNavigate();
  const project = useProject();
  const [template, setTemplate] = useState<Template | null>(null);

  const handleClose = () => {
    setTemplate(null);
    onClose();
  };

  const { mutateAsync: create, isPending } = useCreateConfiguredScreen({ flowId });

  const handleCreate = async () => {
    if (!template) return;

    const data = { template_id: template.id };
    const screen = await create({ data });
    navigate(screen.id);
    handleClose();
  };

  return (
    <Dialog maxWidth="sm" onClose={handleClose} open={isOpen}>
      <ModalPageLayout
        onClose={handleClose}
        name={`Add ${type}`}
        footer={
          <>
            <Button size="large" variant="outlined" onClick={handleClose} color="primary" fullWidth>
              Cancel
            </Button>
            <LoadingButton
              size="large"
              disabled={!template}
              variant="contained"
              onClick={handleCreate}
              loading={isPending}
              fullWidth
            >
              Add
            </LoadingButton>
          </>
        }
      >
        <Box>
          <TemplatesAutocomplete
            type={type}
            value={template}
            fallback={
              <Typography>
                There are no created templates. Please{' '}
                <Link component={RouterLink} to={`/projects/${project.id}/templates/add`}>
                  create
                </Link>{' '}
                a new one.
              </Typography>
            }
            InputProps={{
              label: [type[0].toUpperCase(), ...(type as string).slice(1)].join(''),
            }}
            onChange={(_, tpl) => setTemplate(tpl)}
          />
        </Box>
      </ModalPageLayout>
    </Dialog>
  );
};

export default CreateConfiguredScreenDialog;
