import * as yup from 'yup';
import { PaginationParams, SearchParams, SortingParams } from 'api';
import { ConfiguredScreen } from '../types';

export interface ConfiguredScreenDTO extends Omit<ConfiguredScreen, 'created_at' | 'updated_at'> {
  created_at: string;
  updated_at: string;
}

export type ConfiguredScreensQueryParams = Partial<PaginationParams> & Partial<SortingParams> & Partial<SearchParams>;

export type CreateConfiguredScreenPayload = {
  template_id: number;
};

export const ConfiguredScreenPayloadValidationSchema = yup.object({});
