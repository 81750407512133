import { useProject } from 'features/project';
import { UseGetSynchronousTemplatesQuery, useGetSynchronousTemplatesQuery } from '../api';

export type UseGetTemplatesAutocomplete = Pick<UseGetSynchronousTemplatesQuery, 'config' | 'params' | 'type'>;

export const useGetTemplatesAutocomplete = ({ type, config, params }: UseGetTemplatesAutocomplete) => {
  const project = useProject();
  const projectId = project.id;

  return useGetSynchronousTemplatesQuery({
    type,
    params,
    projectId,
    config,
  });
};
