import { apiClient } from 'api';
import { QueryConfig } from 'lib/react-query';
import { useSuspenseQuery } from '@tanstack/react-query';
import queryKeys from './queryKeys';
import { Flow } from '../types';

export type GetFlowOptions = {
  projectId: number;
  flowId: number;
};

export const getFlow = async ({ projectId, flowId }: GetFlowOptions, signal?: AbortSignal) => {
  const res = await apiClient.get<Flow>(`/admin/projects/${projectId}/flows/${flowId}`, {
    signal,
  });
  return res.data;
};

export type UseGetFlowQuery = {
  config?: QueryConfig<typeof getFlow>;
  projectId: number;
  flowId: number;
};

export const useGetFlowQuery = ({ config, projectId, flowId }: UseGetFlowQuery) =>
  useSuspenseQuery({
    staleTime: 60 * 1000,
    retry: false,
    ...config,
    queryFn: ({ signal }) => getFlow({ projectId, flowId }, signal),
    queryKey: queryKeys(projectId).detail(flowId),
  });
