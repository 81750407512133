import { apiClient } from 'api';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { MutationConfig } from 'lib/react-query';
import { ConfiguredScreenDTO, CreateConfiguredScreenPayload } from './types';
import queryKeys from './queryKeys';
import getConfiguredScreenFromDTO from './getConfiguredScreenFromDTO';
import { ConfiguredScreen } from '../types';

export type CreateConfiguredScreenOptions = {
  projectId: number;
  flowId: number;
  data: CreateConfiguredScreenPayload;
};

export const createConfiguredScreen = async ({
  projectId,
  flowId,
  data,
}: CreateConfiguredScreenOptions): Promise<ConfiguredScreen> => {
  const res = await apiClient.post<ConfiguredScreenDTO>(`/admin/projects/${projectId}/flows/${flowId}/screens`, data);

  return getConfiguredScreenFromDTO(res.data);
};

type MutationFnArgs = { data: CreateConfiguredScreenPayload };
type MutationFnType = (arg: MutationFnArgs) => ReturnType<typeof createConfiguredScreen>;

export interface UseCreateConfiguredScreenMutation {
  projectId: number;
  flowId: number;
  config?: MutationConfig<MutationFnType>;
}

export const useCreateConfiguredScreenMutation = ({ projectId, flowId, config }: UseCreateConfiguredScreenMutation) => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    ...config,
    onSuccess: async (...args) => {
      await queryClient.invalidateQueries({ queryKey: queryKeys(projectId).allLists() });

      if (config?.onSuccess) {
        await config.onSuccess(...args);
      }
    },
    mutationFn: ({ data }: MutationFnArgs) => createConfiguredScreen({ projectId, flowId, data }),
  });

  return mutation;
};
