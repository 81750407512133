import {
  Box,
  Drawer,
  SwipeableDrawer,
  Toolbar,
  useMediaQuery,
  useTheme,
  styled,
  List,
  ThemeProvider,
  Typography,
  colors,
  Stack,
  Chip,
} from '@mui/material';
import { FC } from 'react';
import { capitalize } from 'lodash-es';
import {
  Mediation as FlowsIcon,
  Article as TemplatesIcon,
  Star as FeaturesIcon,
  Stars as FeatureSetsIcon,
  Sell as ProductsIcon,
  Numbers as VersionsIcon,
} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import TestaniaIconSvg from 'assets/testania-logo.svg?react';
import getProjectPagePath from 'pages/projects/project/getProjectPathPath';
import { darkSubTheme } from 'theme';
import { useProject } from 'features/project';
import { useGetFlows } from 'features/flows';
import NavItem from 'components/NavItem';
import { Platform } from 'types';
import { useQueryClient } from '@tanstack/react-query';
import { appQueryKeys } from 'lib/react-query';
import config from 'config';

const TestaniaIcon = styled(TestaniaIconSvg)`
  width: 100%;
`;

const HomeLink = styled(Link)`
  display: flex;
  text-decoration: none;
  object-fit: contain;
  width: 100%;
  height: 100%;
`;

interface SidebarProps {
  isShown: boolean;
  openDrawer: () => void;
  closeDrawer: () => void;
}

const Sidebar: FC<SidebarProps> = ({ isShown, openDrawer, closeDrawer }) => {
  const theme = useTheme();
  const project = useProject();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const queryClient = useQueryClient();
  const apiVersion = queryClient.getQueryData<string>(appQueryKeys.apiVersion);

  const isWebProject = project.platform === Platform.Web;

  const { data: flows } = useGetFlows();

  const getEnvLabel = () => {
    const url = window.location.href;
    // only for local development
    if (url.includes('localhost')) {
      return 'LOCAL';
    }

    switch (config.ENV) {
      case 'prod':
        return 'PROD';
      case 'stage':
        return 'STAGE';
      case 'development':
        return 'DEV';
      case 'feature':
        return 'FEATURE';
      default:
        return 'UNKNOWN';
    }
  };

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
      }}
    >
      <Toolbar>
        <HomeLink to={getProjectPagePath(project.id)}>
          <TestaniaIcon />
        </HomeLink>
        <Chip
          color="primary"
          label={getEnvLabel()}
          size="small"
          sx={{ height: 16, ml: 1, '& .MuiChip-label': { fontSize: '0.625rem', py: 0.25 } }}
        />
      </Toolbar>
      <Stack justifyContent="center" alignItems="center" />

      <Box component="nav" sx={{ flexGrow: 1, pt: 1 }}>
        <List>
          {!isWebProject && (
            <>
              <NavItem to={`${getProjectPagePath(project.id)}/flows`} icon={<FlowsIcon />} title="Flows" expanded>
                {flows?.map((flow) => (
                  <NavItem
                    key={flow.id}
                    to={`${getProjectPagePath(project.id)}/flows/${flow.id}/segments/${flow.segments[0].id}`}
                    sx={{ '& .MuiListItemText-root': { textTransform: 'capitalize' } }}
                    title={
                      <Box title={capitalize(flow.name)} sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                        {flow.name}
                      </Box>
                    }
                  />
                ))}
              </NavItem>
              <NavItem to={`${getProjectPagePath(project.id)}/templates`} icon={<TemplatesIcon />} title="Templates" />
            </>
          )}
          <NavItem
            to={`${getProjectPagePath(project.id)}/feature-sets`}
            icon={<FeatureSetsIcon />}
            title="Feature sets"
          />
        </List>
      </Box>
      <Box sx={{ py: 3 }}>
        <List>
          <NavItem to={`${getProjectPagePath(project.id)}/features`} icon={<FeaturesIcon />} title="Features" />
          <NavItem to={`${getProjectPagePath(project.id)}/products`} icon={<ProductsIcon />} title="Products" />
          {!isWebProject && (
            <NavItem to={`${getProjectPagePath(project.id)}/versions`} icon={<VersionsIcon />} title="Versions" />
          )}
        </List>
        <Stack px={3.5}>
          <Typography variant="caption" sx={{ color: colors.grey[700] }}>
            Sublib version: {project.sublib_version}
          </Typography>
          <Typography variant="caption" sx={{ color: colors.grey[700] }}>
            Testania API version: {apiVersion}
          </Typography>
        </Stack>
      </Box>
    </Box>
  );

  if (isMobile) {
    return (
      <ThemeProvider theme={darkSubTheme}>
        <SwipeableDrawer
          variant="temporary"
          sx={{ zIndex: theme.zIndex.appBar - 1, maxHeight: '100vh' }}
          PaperProps={{
            sx: {
              height: 'unset',
              position: 'absolute',
              bottom: 0,
              width: '100%',
              maxWidth: theme.sizes.sidebarWidth,
              [theme.breakpoints.down('md')]: {
                // top: 65,
                maxWidth: '40%',
              },
              [theme.breakpoints.down('sm')]: {
                // top: 57,
                maxWidth: '100vw',
              },
            },
          }}
          componentsProps={{
            backdrop: () => ({
              sx: {
                [theme.breakpoints.down('md')]: {
                  top: 65,
                },
                [theme.breakpoints.down('sm')]: {
                  top: 57,
                },
              },
            }),
          }}
          open={isShown}
          onOpen={openDrawer}
          onClose={closeDrawer}
        >
          <Toolbar />
          {content}
        </SwipeableDrawer>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={darkSubTheme}>
      <Drawer
        variant="permanent"
        sx={{
          flexShrink: 0,
          width: theme.sizes.sidebarWidth,
        }}
        PaperProps={{
          sx: {
            width: theme.sizes.sidebarWidth,
          },
        }}
      >
        {content}
      </Drawer>
    </ThemeProvider>
  );
};

export default Sidebar;
