import { useProject } from 'features/project';
import { useCreateConfiguredScreenMutation, UseCreateConfiguredScreenMutation } from '../api';

export type UseCreateConfiguredScreen = {
  flowId: number;
  config?: UseCreateConfiguredScreenMutation['config'];
};

export const useCreateConfiguredScreen = ({ config, flowId }: UseCreateConfiguredScreen) => {
  const project = useProject();
  const projectId = project.id;

  return useCreateConfiguredScreenMutation({ config, flowId, projectId });
};
